import { gql } from 'graphql-request';

const appointmentModel = `
  appointmentOwnerId
  appointmentStatus
  calendarId
  createdAt:
  createdById
  description
  end
  externalId
  id
  joinUrl
  profileId
  providerDescription
  start
  title
  updatedAt
`;

export const AttendAppointmentDocument = gql`
  mutation AttendAppointment($id: String!) {
    attendAppointment(id: $id)
  }
`;

export const UpdateTranscriptionDocument = gql`
  mutation UpdateTranscription($input: TranscriptionUpdateDto!) {
    updateTranscription(input: $input)
  }
`;

export const CancelSessionDocument = gql`
  mutation CancelSession($id: String!) {
    deleteAppointment(id: $id)
  }
`;

export const GetSessionsDocument = gql`
  query GetSessions(
    $participantUserId: String, 
    $listNextAppointmentsOnly: Boolean,
    $listPreviousAppointmentsOnly: Boolean,
    $appointmentOwnerId: String,
    $ids: [String!],
    $participants: [String!],
    $start: DateTime, 
    $end: DateTime,
    $limit: Float,
    $offset: Float,
    $order: AppointmentOrderArgType) {
    appointments(
      participantUserId: $participantUserId,
      ids: $ids,
      limit: $limit,
      offset: $offset,
      participants: $participants,
      listNextAppointmentsOnly: $listNextAppointmentsOnly,
      listPreviousAppointmentsOnly: $listPreviousAppointmentsOnly,
      start: $start,
      end: $end,
      appointmentOwnerId: $appointmentOwnerId,
      order: $order
    ) {
      data {
        id
        title
        description
        start
        end
        createdAt
        createdById
        appointmentStatus
        appointmentOwnerId
        appointmentNotes {
          totalCount
          data {
            note
            createdBy
            createdAt
          }
        }
        appointmentParticipants {
          data {
            externalName
            id
            userId
            user {
              id
              type
              avatar
            }
          }
        }
        transcription {
          id
          summaries {
            text
            type
          }
          reports {
            id
            status
          }
        }
        users {
          id
          firstName
          lastName
          avatar
          avatarImageIdentifier
          type
        }
      }
      totalCount
    }
  }
`;

export const GetTotalSessionsDocument = gql`
  query GetTotalSessions(
    $participantUserId: String, 
    $start: DateTime, 
    $end: DateTime,
    $order: AppointmentOrderArgType) {
    appointments(
      participantUserId: $participantUserId,
      start: $start,
      end: $end,
      order: $order
    ) {
      totalCount
    }
  }
`;

export const GetSessionDocument = gql`
  query GetSession($id: String!) {
    appointment(id: $id) {
      appointmentNotes {
        totalCount
        data {
          note
          createdBy
          createdAt
        }
      }
      users {
        id
        firstName
        lastName
        avatar
        avatarImageIdentifier
        type
      }
      appointmentOwnerId
      # appointmentParticipants {
      #   data {
      #     externalName
      #     id
      #     userId
      #   }
      # }
      appointmentStatus
      createdAt
      createdById
      end
      id
      joinUrl
      start
      title
      duration
      description
      updatedAt
      reportType
      guestUrl
      transcription {
        id
        audioUrl
        reports {
          id
          status
        }
        summaries {
          text
          type
        }
        paragraphs {
          text
          speaker
          start
          end
        }
        participants {
          userId
          index
        }
      }
    }
  }
`;

export const CreateSessionNoteDocument = gql`
  mutation CreateAppointmentNote($input: AppointmentNoteCreateDto!) {
    createAppointmentNote(appointmentNote: $input) {
      id
      note
      createdBy
      createdAt
      appointmentId
      createdAt
      updatedAt
    }
  }
`;

export const DeleteSessionNoteDocument = gql`
  mutation DeleteSessionNote($id: String!) {
    deleteAppointmentNote(id: $id)
  }
`;

export const SessionNotesDocument = gql`
  query AppointmentNotes($appointmentId: String!, $order: AppointmentNoteOrderArgType) {
    appointmentNotes(appointmentId: $appointmentId, order: $order) {
      totalCount
      data {
        id
        note
        createdBy
        createdAt
      }
    }
  }
`;

export const UpdateSessionDocument = gql`
  mutation UpdateAppointment($appointment: AppointmentUpdateDto!, $id: String!) {
    updateAppointment(appointment: $appointment, id: $id) {
      appointmentStatus
      createdAt
      createdById
      end
      externalId
      id
      joinUrl
      profileId
      start
      title
      updatedAt
    }
  }
`;

export const SessionRoomDocument = gql`
  query AppointmentRoom($id: String!) {
    appointmentRoom(id: $id) {
      token
      room {
        id
        name
        url
      }
      recordingUrl
    }
  }
`;

export const CreateRoomRecordingDocument = gql`
  mutation CreateRoomRecording(
    $contentRange: String!
    $data: Upload!
    $url: String!
    ) {
    createRecording(
      contentRange: $contentRange,
      data: $data,
      url: $url
    )
  }
`;

export const CreateAppointmentDocument = gql`
  mutation CreateAppointment($appointment: AppointmentCreateArgType!) {
    createAppointment(appointment: $appointment) {
      id
    }
  }
`;

export const GetCoachCalendarsDocument = gql`
  query AppointmentOwnerCalendars($userId: String!) {
    appointmentOwnerCalendars(userId: $userId) {
      calendars {
        calendarId
        calendarPrimary
      }
    }
  }
`;

export const ResendAppointmentGuestUrlDocument = gql`
  mutation ResendAppointmentGuestUrl($email: String!, $id: String!) {
    resendAppointmentGuestUrl(email: $email, id: $id)
  }
`;
