import { BaseProvider, themeOverrides, getTheme, PRIMITIVES } from "@otto-finance/ui";
import { ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { uiSettingsSelector } from "slices/ui/ui.slice";
import { merge } from "lodash";
import { getThemeOverride } from "slices/ui/ui.util";

export const AppTheme = ({ children }: { children: ReactNode; }) => {
  const { site: { theme } } = useSelector(uiSettingsSelector);
  const overrideTheme = useMemo(() => getTheme(PRIMITIVES, merge(themeOverrides, getThemeOverride())), [theme]);
  return (
    <BaseProvider theme={overrideTheme}>
      {children}
    </BaseProvider>
  );
};
